<template>
    <div>
        <v-app class="fondo-login">
                <v-row>
                    <v-col cols="12" sm="6" md="4" class="caja-login" style="padding-left: 4rem; padding-right: 4rem; display: flex; flex-direction: column; justify-content: space-between;">
                        <div style="margin-top:80px; margin-bottom: 100px; margin-left: 1rem; margin-right: 1rem" >
                            <v-img  src="/img/logo1_login.png"></v-img>
                        </div>
                        <div>
                            <p class="text-h4 white--text">Ingreso</p>
                            <br>
                            <label class="white--text" for="input-email">Email</label>
                            <b-form-input id="input-email" v-model="form.email" placeholder="jdoe@mail.com" trim type="email"></b-form-input>
                            <br><br>
                            <label class="white--text" for="input-password">Contraseña</label>
                            <b-form-input id="input-password" v-model="form.password" placeholder="************" trim type="password"></b-form-input>
                            <br><br>
                            <div class="d-flex justify-space-between align-center">
                                <v-btn @click="sendForm" style="background: linear-gradient(90deg, #BF1324 0%, #F95812 100%);" dark>Ingresar</v-btn>
                                <a class="white--text text-subtitle2" @click="showDialog = true" >Contraseña olvidada?</a>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex justify-space-between align-center mt-16">
                                <a class="white--text text-overline" href="https://starkoms.com/" target="_blank">POWERED BY STARKOMS</a>
                                <a class="white--text text-subtitle2" href="#">Docs</a>
                            </div>
                        </div>
                    </v-col>
                    <v-col md="8" class="d-none d-sm-none d-md-inline" style="padding-bottom: 0px;">
                        <img src="/img/capa_vector.png" style="width:100%; height: 100%">
                    </v-col>
                </v-row>
            
        </v-app>
        <v-dialog v-model="showDialog" max-width="600px">
            <v-card >
                <v-card-title class="headline">Recuperar contraseña</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="form">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field v-model="email" label="Correo electrónico" required></v-text-field>
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" @click="showDialog = false" >Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" @click="sendForgotPassword" >Enviar recuperación</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <google-spin v-show="$store.state.passport.loadingStack"></google-spin>
    </div>
</template>

<script>
import {Google} from 'vue-loading-spinner';
export default {
    components: {
        'google-spin': Google,
    },
    data(){
        return {
            email: '',
            showDialog: false,
            form: {
                email: '',
                password: ''
            }
        }
    },
    methods:{
        sendForgotPassword()
        {
            var vm = this;
            this.axios({
                url: 'password_forgot',
                method: 'POST',
                data: {
                    email: this.email
                }
            }).then( response => {
                vm.$bvToast.toast('Se ha enviadó un email con las instrucciones para recuperar su contraseña si el email existe en el sistema', {
                    title: `Notificación`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.email = undefined;
                vm.showDialog = false;
            }).catch( error => {
                vm.$bvToast.toast('Ha ocurrido un error', {
                    title: `Error`,
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.email = undefined;
                vm.showDialog = false;
            });
        },
        sendForm(){
            if(!this.form.email || !this.form.password){
                this.$bvToast.toast("Complete formulario", {
                    title: `Alerta`,
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                return;
            }
            let email = this.form.email;
            let password = this.form.password;
            setTimeout(() => {
                // send login request
                this.$store
                .dispatch('passport/login', { username: email, password })
                // go to which page after successfully login
                .then(() => this.$router.push({ name: "ordenes" }))
                .catch( () => {
                    this.$bvToast.toast("Credenciales incorrectas", {
                        title: `Alerta`,
                        variant: 'warning',
                        solid: true,
                        toaster: 'b-toaster-bottom-center'
                    });
                });
            }, 500);
        }
    },
    created(){
        if( this.$route.query.session ){
            switch(this.$route.query.session){
                default:
                console.log('Sesión cerrada correctamente');
                this.$bvToast.toast("Sesión cerrada correctamente", {
                    title: `Información`,
                    variant: 'info',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                break;
            }
        }
        if( this.$route.query.error_code ){
            switch(this.$route.query.error_code){
                default:
                console.log('Tiempo de sesión expirada o No autorizado');
                break;
            }
        }
    }
}
</script>

<style>

.grid-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 25% auto;
}
@media screen and (max-width: 1360px) {
    .grid-container{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% auto;
    }
}
@media screen and (max-width: 450px) {
    .grid-container{
        width: 100%;
        height: 100%;
        display: flex;
    }
    .caja-decoracion{
        display: none;
    }
}

.caja-login{
    background-color: #050023;
    height: 100%;
}
.caja-decoracion{
    background-color: #5f9ea029;
    height: 100%;
}
.fondo-login{
    background-image: url('/img/shutterstock_1443633284 1.png') !important;
    background-size: cover !important;
}
.spinner.spinner--google{
    z-index: 9999;
    position: fixed;
    right: 2rem;
    bottom: 2rem;
}
  .fondo-login .v-application--wrap {
    height: 100vh !important;
}
</style>